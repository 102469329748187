.image-area{
    background-color: #ececec;
    position:relative;
    border-radius: 0.5rem;
    padding-top: 0.7rem;

    button{
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        opacity:0.8;
    }
    
    .image-area-error{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
    }

    img{
        border-radius: 2rem;
        width:100%;
        height: auto;
    }


}
