@use "../../../../assets/scss/mixins" as m;
@use "../../../../assets/scss/variables" as v;


 .navbar .active {
  font-weight: bold;
  color: red !important;
}


.user-sidebar {

    padding: 2rem !important;
    @media (min-width: 992px) {
        
        height: 100vh;
        align-items: flex-start !important;
        
      }

      .title-icon {
        margin: auto;
        width:50%;
        align-items: left;
        font-size: 4rem;
        text-align: center;
        position: relative;
        float: top;
        border: 0.3rem outset v.$color1;
        border-radius: 10px;
        background-color: white;
        margin-bottom:1rem;
      }

      .title-text {
        width:100%;
        align-items: center;
        text-align: center !important;
      }


  .container {
    @media (min-width: 992px) {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .navbar-brand {
      img {
        @media (max-width: 500px) {
          width: 150px;
          height: auto;
        }
      }
    }

    .navbar-nav {
      flex-direction: column !important;
      margin-top: 1rem;

      @media (min-width: 992px) {
        gap: 1rem;
      }

      .nav-link {
        color: v.$color1 !important;

        svg {
          color: v.$color1;
        }
      }
    }
  }
}