@use "../../../assets/scss/variables" as v;

.books-pagination {
    margin-top: 2rem;
    justify-content: center;
  
    ul {
      width: auto;
      min-width: 0;
      padding: 0;
    }
  }

.book-cards {
  margin:10px;
  padding:10px;
  box-shadow: 0 0 10px v.$color3;
  border: 2px solid #EFEFEF;
  border-radius: 1rem;
}
.loanable-status {
  max-width: 5rem;
  position: absolute;
  left:0;
  top:0;
  opacity: 0.6;
}