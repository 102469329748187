@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.swiper-cont {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
}

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;


    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  @media (max-width: 760px) {
    .swiper-button-next {
      right: 20px;
      transform: rotate(90deg);
    }

    .swiper-button-prev {
      left: 20px;
      transform: rotate(90deg);
    }

   

  }
  .owl-carousel {
    padding: 0 0;
}

.swiper-wrapper{
    padding: 0.5rem;
    text-align: center;

    

    .swiper-slide{
        box-shadow: 0 0 10px v.$color3;
        padding: 0.1rem 1rem;
        border-radius: 1rem;
        cursor: pointer;
    }
}

.arrow{
    background-color: v.$color1;
    border-radius: 50%;
    color: v.$color3;
    font-size: 3rem;
    box-shadow: 2px 3px 10px v.$color5;
    @include m.flexbox(row, center, center ,0);
    cursor: pointer;

    &:hover{
        color: v.$color2;
        box-shadow: 2px 2px 20px v.$color2;
    }
}

.book-title{
  display: block;
  height: 70px;
}
.book-author{
  display: block;
  height: 40px;
}
.feature-books-link {
  color: black;
  text-decoration: none;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}