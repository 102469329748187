
@use "../../../assets/scss/variables" as v;

.section-title{

    text-align: center;
    margin: 0;
    padding: 0;
    z-index: 6;
    margin-top:1rem;
    

    h5{
        font-weight: 700;
        font-size: 1.5rem;
        color: v.$color1;
    }

    h2{
        font-weight: 700;
        font-size: 3rem;
        color: v.$color1;
        margin-bottom:0;
    
    }

    p{
        color: v.$color5;
    } 
}

.animate-character
{
    font-weight: 700;
    font-size: 3rem;
    margin-top: -50px;
    z-index: 7;

   
  background-image: linear-gradient(
    -225deg,
    v.$color1 0%,
    v.$color2 29%,
    #316400 67%,
    #53370c 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
 
  
}

@keyframes textclip {
  to {
    background-position: 200% center;
    
  }
}