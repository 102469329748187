$color1: rgb(139, 197, 63);
$color2: rgb(34, 52, 98);
$color3: rgb(237, 237, 237);
$color4: rgba(139, 197, 63, 0.5);
$color5: rgba(34, 52, 98, 0.5);

/*  BOOTSTRAP */
$primary                    : $color1;
$navbar-light-color         : $color2;
$navbar-light-hover-color   : $color1;
$navbar-light-active        : $color1;
$font-family-sans-serif     : "Jost";


/* $border-radius              : 2rem; */
$input-btn-padding-y        : 1rem;
$input-btn-padding-x        : 2rem;

/* SWEET ALERTS */
$swal2-confirm-button-background-color  : $color1;
$swal2-cancel-button-background-color   : $color2;
$swal2-question                         : $color1;

/* SWIPER */
$themeColor                 : $color1;