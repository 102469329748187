@mixin reset-list {
    list-style: none;
    padding:0;
    margin:0;
}

@mixin flexbox($direction:row, $justify-content:flex-start, $align-items: flex-start, $gap:0) {
    display: flex;
    justify-content: $justify-content;
    flex-direction: $direction;
    align-items: $align-items;
    gap:$gap;
}