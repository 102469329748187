@use "../../../../assets/scss/variables" as v;
@use "../../../../assets/scss/mixins" as m;

.auth {
  div[class^="col"] {
    &:first-child {
      background-image: url("../../../../assets/img/login-bg.jpg");
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      position: relative;
      @include m.flexbox(row, center, center, 0);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: v.$color4;
        opacity: 0.8;
      }

      @media (max-width: 991px) {
        min-height: auto;
        
      }
    }

    &:last-child {
      padding: 4rem;
    }

    img {
      position: relative;
      z-index: 10;

      @media (max-width: 991px) {
        width: 150px;
        height: auto;
      }
    }

    .toolbar {
      position: absolute;
      top: 1rem;
      left: 1rem;
      color: red;
      z-index: 10;
      font-size: 3rem;
      cursor: pointer;
      

      svg {
        &:hover {
          color: white;
        }
      }
    }

    .form-area {
      margin-top:1rem;
      position:relative;
      z-index: 23;

      .card {
        min-width: 60vw;

        @media (min-width: 576px) {
          min-width: 50vw;
        }

        @media (min-width: 767px) {
          min-width: 40vw;
        }

        @media (min-width: 991px) {
          min-width: 30vw;
        }

      }
      .title-icon {
        margin: auto;
        width:50%;
        align-items: center;
        font-size: 4rem;
        text-align: center;
        position: relative;
        float: top;
        top:-60px;
        border: 0.3rem outset v.$color5;
        border-radius: 10px;
        background-color: white;
     
        
      }
      .form-items {
        margin-top:-2rem;
      }
    
    }
  }

  
}
