@use "../../../assets/scss/variables" as v;

.admin-users-page {
    width:80%;
}


.breadcrumb-box {
    display:block;
    padding: 0;
    height: 3rem;
    background-color: v.$color2;
    padding-top:0.8rem;
    padding-left:2rem;
    
    
}

.search-box {
    display:block;
    padding: 0;
    height: 6rem;
    background-color: v.$color3;
    padding-top:0.8rem;
  
}

.book-list-container {
    display:block;

    width: 80%; 
    height:auto;
}


.image-area{
    background-color: #ececec;
    position:relative;
    border-radius: 0.5rem;
    padding-top: 0.7rem;

    button{
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        opacity:0.8;
    }
    
    .image-area-error{
        position:absolute;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
    }

    img{
        border-radius: 0.5rem;
        width:100%;
        height: auto;
    }


}

[data-column-id="name"].rdt_TableCol {
    background-color: v.$color5;
    font-weight: 800;
    font-size:1rem;
  }

  [data-column-id="loan-date"].rdt_TableCol {
    background-color: v.$color5;
    font-weight: 800;
    font-size:1rem;
  }
  [data-column-id="expire-date"].rdt_TableCol {
    background-color: v.$color5;
    font-weight: 800;
    font-size:1rem;
  }
  [data-column-id="return-date"].rdt_TableCol {
    background-color: v.$color5;
    font-weight: 800;
    font-size:1rem;
  }
  [data-column-id="edit"].rdt_TableCol {
    background-color: v.$color5;
    font-weight: 800;
    font-size:1rem;
    text-align: center;
  }
.edit-button {
    width:100%;
    height:3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #EFEFEF;
}

.return-status-form {
    height:230px;
    background-color: v.$color3;
}

.loan-form {
    background-color:#EFEFEF;
    box-shadow: 1px 1px 5px gray;
    padding-bottom:1rem;
}
.search-area {
    margin: 1rem 2rem;
    padding-top:2rem;
}


