@use "../../../assets/scss/variables" as v;

.admin-authors-page {

    @media (min-width: 992px) {
        
        width: 80%;
       
        
      }
}



.breadcrumb-box {
    display:block;
    padding: 0;
    height: 3rem;
    background-color: v.$color2;
    padding-top:0.8rem;
    padding-left:2rem;
    
    
}

.search-box {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    gap:0;
    padding: 0;
    height: 6rem;
    background-color: v.$color3;
    padding-top:0.8rem;
  
}

